import React from 'react'
import aboutImg1 from '../../assets/images/about/about-img1.png'
import {Link} from 'gatsby'

const Content = () => {
    return (
        <div className="upcoming-bootcamps-area ptb-70">
            <div className="container">
                <div className="upcoming-bootcamps-content">
                    <h2 className='pb-50'>Protégez vos collaborateurs, vos données et la réputation de votre organisation !</h2>
                    <h3 className="ptb-30 color-compliance"><i class='bx bxs-megaphone bx-tada'></i> Dispositif de signalement éthique</h3>
                    <p className='article-link'>La <span className='bold'><Link to="#">loi Sapin 2</Link></span> ainsi que la directive européenne sur la protection des lanceurs d’alertes imposent aux entreprises, associations et établissements publics d’<span className='bold'>au moins 50 salariés</span> et les collectivités de plus de 10 000 habitants de mettre en place un <span className='bold'>dispositif de recueil et de traitement des alertes</span>.</p>
                    <p>Ce dispositif vous permet de recevoir et traiter des alertes ainsi que de diffuser à l’ensemble de vos collaborateurs des procédures de prévention.</p>
                </div>

                <div className="overview-box">
                    <div className="overview-content">
                        <div className="content">
                            <h3>La plateforme Saas Wispeek met à votre disposition :</h3>
                            <div className="features-text">
                                <h4><i className="flaticon-tick"></i> Plateforme de dépôt de signalement confidentiel ou anonyme</h4>
                            </div>

                            <div className="features-text">
                                <h4><i className="flaticon-tick"></i> Un récépissé de dépôt de signalement</h4>
                            </div>

                            <div className="features-text">
                                <h4><i className="flaticon-tick"></i> Plateforme de traitement de signalement pour les référents</h4>
                            </div>

                            <div className="features-text">
                                <h4><i className="flaticon-tick"></i> Système de notification des signalements</h4>
                            </div>

                            <div className="features-text">
                                <h4><i className="flaticon-tick"></i> Messagerie confidentielle et sécurisée</h4>
                            </div>

                            <div className="features-text">
                                <h4><i className="flaticon-tick"></i> Bibliothèque de procédures internes et éthiques</h4>
                            </div>

                            <div className="features-text">
                                <h4><i className="flaticon-tick"></i> Archivage des données conforme CNIL et RGPD</h4>
                            </div>

                            <div className="features-text">
                                <h4><i className="flaticon-tick"></i> Tableau de bord interactif</h4>
                            </div>
                        </div>
                    </div>

                    <div className="overview-image">
                        <div className="image">
                            <img src={aboutImg1} alt="about" />
                        </div>
                    </div>

                </div>
            </div>

            <div className="container pt-70 center">
                <div className="upcoming-bootcamps-content">
                    <h4 >Si vous recherchez à vous mettre en conformité avec un système de signalement simple et efficace, ce dispositif est celui qu’il vous faut !</h4>
                    <Link to="/offre-wispeek" className="default-btn">
                        <i className="flaticon-right"></i>
                        Voir nos offres
                    </Link>
                </div>
            </div>
        </div>
    )
}

export default Content;