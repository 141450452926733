import React from "react"
import Navbar from "../components/App/Navbar"
import Footer from "../components/App/Footer"
import Layout from "../components/App/Layout"
import PageBanner from '../components/Common/PageBanner'
import UnProjet from "../components/Common/UnProjet"
import ModCompliance from "../components/Compliance/ModCompliance"
import Content from "../components/Compliance/Content"
import ContentTwo from "../components/Compliance/ContentTwo"
import CartesAvantages from "../components/Compliance/CartesAvantages"
import WhyWispeek from "../components/Compliance/WhyWispeek"
import ContentThree from "../components/Compliance/ContentThree"
import { Helmet } from "react-helmet"
import Video from '../components/Compliance/Video'

const Compliance = () => {
  return (
    <Layout>
      <Helmet>
        <title>Wispeek by Seekreet | Plateforme de mise en conformité Loi sapin 2 </title>
        <meta name="description" content="Gestion des mises en conformité : loi du 21 mars 2022, la loi Sapin 2, directive européenne protection des lanceurs d’alertes, devoir de vigilance, référent harcèlement, Covid, RSE, etc." />
      </Helmet>
      <Navbar />
      <PageBanner
          pageTitle="Wispeek - Compliance" 
          homePageText="Accueil" 
          homePageUrl="/"
          parentActive="Wispeek" 
          parentPageUrl="/wispeek"
          activePageText="Compliance" 
      />
      <ModCompliance />
      <Content />
      <ContentTwo />
      <ContentThree />
      <Video />
      <WhyWispeek />
      <CartesAvantages />
      <UnProjet />
      <Footer />
    </Layout>
  )
}

export default Compliance