import React from 'react'
//import mission2 from '../../assets/images/our-mission/our-mission2.png'
//import shape1 from '../../assets/images/our-mission/our-mission-shape1.png'

const CartesAvantages = () => {
    return (
        <div className="our-mission-area ptb-70">
            <div className="container">
                <div className="row" id="compliance">
                    <div className="col-lg-2 col-sm-6">
                        <div className="single-solutions-box">
                            <div className="icon">
                                <i class='bx bxs-user-check color-compliance' ></i>
                            </div>
                            <h3>
                                Ergonomique
                            </h3>
                        </div>
                    </div>

                    <div className="col-lg-2 col-sm-6">
                        <div className="single-solutions-box">
                            <div className="icon">
                                <i class='bx bxs-lock color-compliance' ></i>
                            </div>
                            <h3>
                                Sécurisé
                            </h3>
                        </div>
                    </div>

                    <div className="col-lg-2 col-sm-6">
                        <div className="single-solutions-box">
                            <div className="icon">
                                <i class='bx bxs-rocket color-compliance' ></i>
                            </div>
                            <h3>
                                Evolutif
                            </h3>
                        </div>
                    </div>

                    <div className="col-lg-2 col-sm-6">
                        <div className="single-solutions-box">
                            <div className="icon">
                                <i class='bx bx-world color-compliance'></i>
                            </div>
                            <h3>
                                Multilingue
                            </h3>
                        </div>
                    </div>

                    <div className="col-lg-2 col-sm-6">
                        <div className="single-solutions-box">
                            <div className="icon">
                                <i class='bx bxs-map-pin color-compliance'></i>
                            </div>
                            <h3>
                                <span>100%</span>&nbsp;Français
                            </h3>
                        </div>
                    </div>

                    <div className="col-lg-2 col-sm-6">
                        <div className="single-solutions-box ">
                            <div className="icon">
                                <i class='bx bxs-devices color-compliance'></i>
                            </div>
                            <h3>
                                Responsive
                            </h3>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    )
}

export default CartesAvantages