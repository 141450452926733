import React from 'react'
import { Link } from 'gatsby'
import img12 from '../../assets/images/about/about-img12.png'
import shape1 from '../../assets/images/about/about-shape1.png'
import starIcon from '../../assets/images/star-icon.png'

const ModCompliance = () => {
    return (
        <div className="about-area ptb-70 bg-compliance">
            <div className="container-fluid">
                <div className="row align-items-center">
                    <div className="col-lg-6 col-md-12">
                        <div className="about-img">
                            <img src={img12} alt="banner" />
                            <div className="shape">
                                <img src={shape1} alt="banner" />
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-6 col-md-12">
                        <div className="about-content">
                            <div className="content">
                                <span className="sub-title">
                                    <img src={starIcon} alt="banner" /> 
                                    Wispeek Compliance
                                </span>
                                <h2 className='pb-30'>Dispositif de signalement professionnel</h2>
                                <h3>Développez une fonction compliance éthique à long terme</h3>
                                <p>Wispeek accompagne les organisations dans la gestion de leurs mises en conformité : <span className='bold'>loi du 21 mars 2022 qui améliore la loi Sapin 2 et la directive européenne sur la protection des lanceurs d’alertes, devoir de vigilance, référent harcèlement, Covid, RSE, etc.</span></p>
                                <p>Les <span className='bold'>problématiques de gouvernance et de compliance</span> sont des enjeux non négligeables concernant la responsabilité des entreprises et leurs engagements d’un point de vue juridique, sociétal et éthique.</p>
                                <p>La plateforme s’adapte à votre organisation et à vos besoins en vous proposant des <span className='bold'>fonctionnalités modulables et paramétrables selon le degré de maturité de votre système de conformité</span>.</p>
                                <Link to="/contact" className="default-btn">
                                    <i className="flaticon-right"></i> 
                                    Demandez une démo <span></span>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ModCompliance