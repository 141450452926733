import React from 'react'
import { Link } from 'gatsby'
import aboutImg3 from '../../assets/images/about/about-img3.png'

const ContentTwo = () => {
    return (
        <div className="upcoming-bootcamps-area ptb-70 bg-compliance">
            <div className="container">
                <div className="upcoming-bootcamps-content">
                    <h3 className="ptb-30 color-compliance"><i class='bx bx-shield-quarter bx-tada' ></i> Dispositif anticorruption</h3>
                    <p>Cette obligation vise à la prévention et la détection de la corruption privée et publique de l’<span className="bold">article 17 de la loi Sapin 2</span>.
                    Elle concerne les entreprises, les dirigeants d’entreprises et les groupes dont le siège social se situe en France de <span className="bold">plus de 500 salariés</span> dont le CA est supérieur à 100 millions €.</p>
                    <p>Ce dispositif vous permet de mettre en place des <span className="bold">procédures de détection, de suivi, de contrôle et de prévention des risques</span> de corruption, de fraude, de conflits d’intérêts et de toute situation à risque pour vos collaborateurs et l’entreprise.</p>
                    <p>Il vous permet de recevoir et traiter des alertes ainsi que de diffuser à l’ensemble de vos collaborateurs des procédures de prévention.</p>
                </div>

                <div className="overview-box">

                     <div className="overview-image">
                        <div className="image">
                            <img src={aboutImg3} alt="about" />
                        </div>
                    </div>

                    <div className="overview-content">
                        <div className="content">
                            <h3>Déployer facilement votre dispositif anticorruption :</h3>
                            <div className="features-text">
                                <h4><i className="flaticon-tick"></i> Dispositif d’alertes éthiques</h4>
                            </div>

                            <div className="features-text">
                                <h4><i className="flaticon-tick"></i> Cartographie des risques</h4>
                            </div>

                            <div className="features-text">
                                <h4><i className="flaticon-tick"></i> Formulaires d’évaluation des tiers</h4>
                            </div>

                            <div className="features-text">
                                <h4><i className="flaticon-tick"></i> Bibliothèque de procédures éthiques et de sensibilisation</h4>
                            </div>

                            <div className="features-text">
                                <h4><i className="flaticon-tick"></i> Formulaires d’audits et de contrôles internes</h4>
                            </div>
                        </div>
                    </div>

                </div>
            </div>

            <div className="container">
                <div className="upcoming-bootcamps-content">
                    <h4 className="h2-top center">Si vous êtes à la recherche d’un dispositif plus complet, notre dispositif anticorruption vous propose des fonctionnalités conformes aux réglementations en vigueur !</h4>
                </div>
            </div>
            <br></br>
            <div className="container">
                <blockquote className="h2-top">
                    <p className='color-compliance'>Bien plus qu’une simple démarche de mise en conformité, la compliance représente un levier de performance pour chaque organisation et une preuve de son engagement responsable.</p>
                    <cite>Seekreet</cite>
                </blockquote>
            </div>

            <div className="container pt-30 center">
                <Link to="/offre-wispeek" className="default-btn">
                    <i className="flaticon-right"></i>
                        Voir nos offres
                </Link>
            </div>
        </div>
    )
}

export default ContentTwo;