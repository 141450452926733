import React from 'react'
//import mission2 from '../../assets/images/our-mission/our-mission2.png'
//import shape1 from '../../assets/images/our-mission/our-mission-shape1.png'
import img1 from '../../assets/images/banner-img3.png'
import starIcon from '../../assets/images/star-icon.png'

const WhyWispeek = () => {
    return (
        <div className="overview-area ptb-70">
            <div className="container">
                <div className="overview-box">
                    <div className="overview-content">
                        <div className="content">
                            <div>
                                <div className="about-content">
                                    <div className="content">
                                        <span className="sub-title">
                                            <img src={starIcon} alt="banner" /> 
                                            Pourquoi nous ?
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <h2>Pourquoi choisir Wispeek ? </h2>
                            <h3>Une plateforme Saas française « clé en main »</h3>
                            <p>Notre solution simplifie votre démarche de mise en conformité de par sa simplicité de mise en place et d’utilisation. <span className='bold'>Gérez et automatisez votre système de conformité sur un seul outil</span>.</p>    
                            <ul className="features-list-custom">
                                <li><span><i className='bx bx-check'></i> Garantit la confidentialité ou l’anonymat</span></li>
                                <li><span><i className='bx bx-check'></i> Traitement rapide et sécurisé par un référent habilité</span></li>
                                <li><span><i className='bx bx-check'></i> Contrôle des droits d’accès</span></li>
                                <li><span><i className='bx bx-check'></i> Répond aux normes RGPD et de l’AFA (agence française anticorruption)</span></li>
                                <li><span><i className='bx bx-check'></i> Test de fonctionnement mensuel de la plateforme</span></li>
                            </ul>
                        </div>
                    </div>

                    <div className="overview-image">
                        <div className="image">
                            <img src={img1} alt="features" />
                        </div>
                    </div>
                </div>
            </div>
            <div className="container pt-70">
                <div className="upcoming-bootcamps-content">
                    <h4 className="center">La solution la mieux adaptée face aux exigences des normes en vigueur !</h4>
                </div>
            </div>
        </div>
    )
}

export default WhyWispeek